if (localStorage.getItem("roles")) {
  var baseUrll = "college";
  if (localStorage.getItem("roles").includes("ROLE_ADMIN")) {
    baseUrll = "admin";
  } else if (localStorage.getItem("roles").includes("ROLE_COLLEGEGROUP")) {
    baseUrll = "cgroup";
  } else if (localStorage.getItem("roles").includes("ROLE_COLLEGE")) {
    baseUrll = "college";
  }
}

const baseUrl = "https://backend.svgpayphone.in/api/";
const baseUrladmin = "https://backend.svgpayphone.in/" + baseUrll + "/api/v1/";
const baseUrladminV2 =
  "https://backend.svgpayphone.in/" + baseUrll + "/api/v2/";
const baseUrlForUser =
  "https://backend.svgpayphone.in/" + baseUrll + "api/v1/";

const dashboardurl = "https://backend.svgpayphone.in/";
// const baseUrl =  'http://localhost:9999/api/';
// const baseUrladmin =  'http://localhost:9999/' + baseUrll + '/api/v1/';
// const dashboardurl = 'http://localhost:9999/' ;

export { baseUrl, baseUrladmin, baseUrladminV2, dashboardurl, baseUrlForUser };
