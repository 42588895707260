// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useState, useEffect, useMemo, useContext } from "react";
// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import {
  baseUrl,
  baseUrladmin,
  dashboardurl,
} from "../../assets/js/config/config.js";
// Dashboard components
import "./dashboard.css";
import Spinner from "../tables/spinner";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";

import { Table, Tooltip } from "antd";
import DropdownContext from "shared/ContextStore/DropdownContext.js";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const AccessToken = localStorage.getItem("accessToken");
  const [rows, setPosts] = useState([]);
  const [Sndrows, setSndRows] = useState([]);
  const [posRows, setPosRows] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [phoneRows, setPhoneRows] = useState([]);
  const [trows, settrows] = useState([]);
  const [editdata, setEditdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showModal, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const { state } = useLocation();

  const [activeTable, setActiveTable] = useState([]);
  const [tableStatus, setTableStatus] = useState("");
  const [tableStatusPhone, setTableStatusPhone] = useState("");
  const [lowBalancePos, setLowBalancePos] = useState([]);
  const [lowPhoneUtilize, setLowPhoneUtilize] = useState([]);

  const [lowBalancePosRows, setLowBalancePosRows] = useState([]);
  const [InActiveTable, setInActiveTable] = useState([]);
  const { dropdownValue, dropdownName } = useContext(DropdownContext);
  console.log(dropdownName, "dropdownName-das");
  const [companyTypes, setCompanyTypes] = useState([]);
  const initialCompanyId = localStorage.getItem("companyId");
  const initialCompanyName = companyTypes.find(
    (company) => company.companyId === initialCompanyId
  )?.companyName;

  useEffect(() => {
    fetch(baseUrladmin + "company/getAllCollegesUnderMe", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      body: JSON.stringify({
        companyId: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == -1) {
          setCompanyTypes([]);
        } else {
          setCompanyTypes(data.data);
          if (roles === "ROLE_COLLEGE") {
            setcmptype(data.data[0]?.companyId);
          }
        }
        setdestcmpid(data.data[0]?.companyId);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetchDefibs(); // here
    fetchTableData();
    handleRowPosClick();
    handleRowClick();
  }, [dropdownValue]);

  if (state == null) {
    const roles = localStorage.getItem("roles");
    if (roles.includes("ROLE_COLLEGEGROUP")) {
      var baseUr = "cgroup/api/v1/";
    } else if (roles.includes("ROLE_COLLEGE")) {
      var baseUr = "college/api/v1/";
    } else {
      var baseUr = "admin/api/v1/";
    }
  } else {
    if (state.includes("ROLE_COLLEGEGROUP")) {
      var baseUr = "cgroup/api/v1/";
    } else if (state.includes("ROLE_COLLEGE")) {
      var baseUr = "college/api/v1/";
    } else {
      var baseUr = "admin/api/v1/";
    }
  }

  const fetchDefibs = () => {
    fetch(dashboardurl + baseUr + "dashboard/getDashboardDetails", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // setPosts(data.data.lstCompanies);
        settrows(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchTableData = () => {
    fetch(dashboardurl + baseUr + "report/getPOSDevicesCountByState", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      method: "POST",
      body: JSON.stringify({ companyId: localStorage.getItem("companyId") }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPosts(data?.data);
        // settrows(data?.data);
        setPosRows(data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
    fetch(dashboardurl + baseUr + "report/getPhonesCountByState", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      method: "POST",
      body: JSON.stringify({ companyId: localStorage.getItem("companyId") }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSndRows(data?.data);
        setPhoneRows(data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const redirecttoDevices = (e) => {
    navigate("/devicedata", { state: "pos" });
  };

  const rowSelection = {
    type: "radio",
    onChange: (selectedRowKeys) => {
      setSelectedRow(selectedRowKeys[0]);
    },
  };

  const handleRowPosClick = (record) => {
    fetch(dashboardurl + baseUr + "report/getLowBalancePOSByCollegeId", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      method: "POST",
      body: JSON.stringify({
        companyId: localStorage.getItem("companyId"),
        collGroupId: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Ensure that data?.data is always an array before setting it to the state
        const tableData = Array.isArray(data?.data) ? data?.data : [];
        setLowBalancePos(tableData);
        setLowBalancePosRows(tableData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.message);
        setLowBalancePos([]); // Same for the active table
        setLowBalancePosRows([]);
        setLoading(false);
      });

    fetch(dashboardurl + baseUr + "report/getPhoneUtilization", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      method: "POST",
      body: JSON.stringify({
        companyId: localStorage.getItem("companyId"),
        // collGroupId: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Ensure that data?.data is always an array before setting it to the state
        const tableData = Array.isArray(data?.data) ? data?.data : [];
        setLowPhoneUtilize(tableData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.message);
        setLowPhoneUtilize([]);
        setLoading(false);
      });
  };
  const handleRowClick = (record) => {
    // console.log(`ID: ${record?.campusId}, Name: ${record?.campusName}`);
    setTableStatusPhone(record?.campusName);
    fetch(dashboardurl + baseUr + "report/getAllActiveDevices", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      method: "POST",
      body: JSON.stringify({
        companyId: localStorage.getItem("companyId"),
        collGroupId: record?.campusId || "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Ensure that data?.data is always an array before setting it to the state
        const tableData = Array.isArray(data?.data) ? data?.data : [];

        setActiveTable(tableData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.message);
        setShowSelectedTable(false);
        // Handle the error state properly
        setTableRows([]); // Set to empty array if there's an error
        setActiveTable([]); // Same for the active table
        setLoading(false);
      });

    fetch(dashboardurl + baseUr + "report/getAllInActiveDevices", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      method: "POST",
      body: JSON.stringify({
        companyId: localStorage.getItem("companyId"),
        collGroupId: record?.campusId || "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Ensure that data?.data is always an array before setting it to the state
        const tableData = Array.isArray(data?.data) ? data?.data : [];
        setInActiveTable(tableData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.message);
        setLoading(false);
      });
  };

  const renderStatus = (value, key, record, rowClick) => {
    if (key === "deviceName") {
      if (record) {
        const dA = record?.deviceAddress?.slice(6);
        return (
          <Tooltip title={record.deviceName + " - " + dA}>
            <div>{record.deviceName + " - " + dA}</div>
          </Tooltip>
        );
      }
    }

    if (key === "totalDevices") {
      if (record) {
        return (
          <div className={rowClick === "rowClick" ? "hyperlink" : ""}>
            {record.activeDevices + record.nonActiveDevices}
          </div>
        );
      }
    }
    if (key === "activeDevices" || key === "nonActiveDevices") {
      if (record) {
        return (
          <div className={rowClick === "rowClick" ? "hyperlink" : ""}>
            {value || 0}
          </div>
        );
      }
    }
    // Handling other truthy values with ellipsis and tooltip
    if (value) {
      return (
        <Tooltip title={value}>
          <div className={rowClick === "rowClick" ? "hyperlink" : ""}>
            {value || "NA"}
          </div>
        </Tooltip>
      );
    }

    // Default case for falsy values other than boolean false
    return "NA";
  };

  const columnsMain = [
    {
      title: "Campus",
      dataIndex: "campusName",
      key: "campusName",
      width: 200,
      sorter: true,
      render: renderStatus,
    },
    {
      title: "Active",
      dataIndex: "activeDevices",
      key: "activeDevices",
      align: "center",
      sorter: true,
      render: (value, record) => renderStatus(value, "activeDevices", record),
    },
    {
      title: "InActive",
      dataIndex: "nonActiveDevices",
      align: "center",
      key: "nonActiveDevices",
      sorter: true,
      render: (value, record) =>
        renderStatus(value, "nonActiveDevices", record),
    },
    {
      title: "Total",
      dataIndex: "totalDevices",
      key: "totalDevices",
      align: "center",
      sorter: true,
      render: (value, record) => renderStatus(value, "totalDevices", record),
    },
  ];

  const columnsMainPhone = [
    {
      title: "Campus",
      dataIndex: "campusName",
      key: "campusName",
      width: 200,
      sorter: true,
      render: (value, record) =>
        renderStatus(value, "campusName", record, "rowClick"),
    },
    {
      title: "Active",
      dataIndex: "activeDevices",
      key: "activeDevices",
      align: "center",
      sorter: true,
      render: (value, record) =>
        renderStatus(value, "activeDevices", record, "rowClick"),
    },
    {
      title: "InActive",
      dataIndex: "nonActiveDevices",
      align: "center",
      key: "nonActiveDevices",
      sorter: true,
      render: (value, record) =>
        renderStatus(value, "nonActiveDevices", record, "rowClick"),
    },
    {
      title: "Total",
      dataIndex: "totalDevices",
      key: "totalDevices",
      align: "center",
      sorter: true,
      render: (value, record) =>
        renderStatus(value, "totalDevices", record, "rowClick"),
    },
  ];

  const columns = [
    {
      title: "Devices",
      dataIndex: "deviceName",
      key: "deviceName",
      width: 200,
      sorter: true,
      render: (value, record) => renderStatus(value, "deviceName", record),
    },
    {
      title: "Validity",
      dataIndex: "validity",
      key: "validity",
      align: "center",
      sorter: true,
      render: renderStatus,
    },
    {
      title: "LastSeen",
      dataIndex: "lastSeen",
      align: "center",
      key: "lastSeen",
      sorter: true,
      render: renderStatus,
    },
  ];

  const columnsLowBalance = [
    {
      title: "POS",
      dataIndex: "deviceName",
      key: "deviceName",
      width: 200,
      sorter: true,
      // render: renderStatus,
      render: (value, record) => renderStatus(value, "deviceName", record),
    },
    {
      title: "Balance",
      dataIndex: "balanceAmount",
      key: "balanceAmount",
      align: "center",
      sorter: true,
      render: renderStatus,
    },
    {
      title: "Campus",
      dataIndex: "campusName",
      align: "center",
      key: "campusName",
      sorter: true,
      render: renderStatus,
    },
  ];

  const columnsLowUtilization = [
    {
      title: "Phone",
      dataIndex: "deviceName",
      key: "deviceName",
      sorter: true,
      render: (value, record) => renderStatus(value, "deviceName", record),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      align: "center",
      sorter: true,
      render: renderStatus,
    },
  ];

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="devices"
                    title={trows.companyName}
                    count={trows.companyType}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="money"
                    title={trows.balanceAmount}
                    count="Balance"
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Balance",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={2.4}>
                <MDBox
                  mb={1.5}
                  onClick={redirecttoDevices}
                  style={{ cursor: "pointer" }}
                >
                  <ComplexStatisticsCard
                    color="success"
                    icon="fax"
                    title={trows.noOfPOS}
                    count="No of POS"
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "Company Type",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="phone"
                    title={trows.noOfPhone}
                    count="No of Phones"
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="money"
                    title={trows.totalPOSBalance}
                    count="Total POS Balance"
                    percentage={{
                      color: "success",
                      amount: "",
                      label: "",
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={6} pb={3} style={{ paddingTop: "0px" }}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Card>
                  <MDBox
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      POS Devices
                      {initialCompanyName ? "-" + " " + initialCompanyName : ""}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={0}>
                    {!loading ? (
                      <Table
                        columns={columnsMain}
                        dataSource={posRows}
                        // onRow={(record) => ({
                        //   onClick: () => handleRowPosClick(record),
                        // })}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <MDBox
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Phones{" "}
                      {initialCompanyName ? "-" + " " + initialCompanyName : ""}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={0}>
                    {!loading ? (
                      <Table
                        columns={columnsMainPhone}
                        dataSource={phoneRows}
                        onRow={(record) => ({
                          onClick: () => handleRowClick(record),
                        })}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>

          {/* Active InActive selected table row related data will display here */}
          <MDBox pt={6} pb={3} style={{ paddingTop: "0px" }}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Card>
                  <MDBox
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Active Phones{" "}
                      {tableStatusPhone ? "-" + " " + tableStatusPhone : ""}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={0}>
                    {!loading ? (
                      <Table columns={columns} dataSource={activeTable} />
                    ) : (
                      <Spinner />
                    )}
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <MDBox
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      InActive Phones{" "}
                      {tableStatusPhone ? "-" + " " + tableStatusPhone : ""}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={0}>
                    {!loading ? (
                      <Table columns={columns} dataSource={InActiveTable} />
                    ) : (
                      <Spinner />
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox pt={6} pb={3} style={{ paddingTop: "0px" }}>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Card>
                  <MDBox
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      POS Low Balance Grid{" "}
                      {initialCompanyName ? "-" + " " + initialCompanyName : ""}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    {!loading ? (
                      <Table
                        columns={columnsLowBalance}
                        dataSource={lowBalancePos}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <MDBox
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Phones Low Utilization{" "}
                      {initialCompanyName ? "-" + " " + initialCompanyName : ""}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    {!loading ? (
                      <Table
                        columns={columnsLowUtilization}
                        dataSource={lowPhoneUtilize}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </DashboardLayout>
      )}
    </div>
  );
}

export default Dashboard;
